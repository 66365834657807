import * as React from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Utilities
import { setFailedCrudAlert } from 'core/utilities/helper';
import { generateMenuItem } from 'features/menu/menus/utilities/helper';

// Context
import { useAddMenuItemMutation } from 'features/menu/menus/hooks';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  addMenu,
  useSelectItemsWithNoParentCount,
  useSelectMenuActionMode,
  useSelectMenuContainerId,
} from 'core/store/slices/feature/menu';

interface AddMenuButtonProps {}

const AddMenuButton: React.FC<AddMenuButtonProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const actionMode = useSelectMenuActionMode();
  const containerId = useSelectMenuContainerId();
  const parentCounts = useSelectItemsWithNoParentCount();

  // Hooks
  const { palette } = useTheme();
  const { mutate: handleAddMenu } = useAddMenuItemMutation({
    onSuccess: ({ data: item }) => {
      if (!item) return;
      addMenu({
        id: item.id,
        data: {
          description: item?.data?.description || '',
          hasSubItems: false,
          image: null,
          isActive: true,
          link: '',
          parentIds: [],
          parentId: null,
          relatedMediaId: item.data.relatedMediaId || '',
          title: item.data.title || '',
          sortNumber: parentCounts,
        },
      });
    },
    onError: () => setFailedCrudAlert('create', 'آیتم منو'),
  });

  // Utilities

  // Render
  return (
    <Button
      component={Paper}
      disabled={loading || actionMode !== 'NORMAL'}
      color='inherit'
      startIcon={<PlusCircleOutlineIcon />}
      onClick={() =>
        handleAddMenu({ containerId: containerId, data: generateMenuItem() })
      }
      sx={{
        backgroundColor: palette.background.paper,
        color: palette.info.main,
        minWidth: 'fit-content',
        borderRadius: '.5rem',
        padding: '.5rem .75rem',
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
      }}
    >
      افزودن منو
    </Button>
  );
};

export default AddMenuButton;
