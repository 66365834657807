import { useCallback, useState, useEffect } from 'react';

// Custom Common Components
import DataGrid from 'core/components/shared/DataGrid';

// Core Components
import Typography from 'core/components/base/display/Typography';

// Components
import CopyToClipboard from 'core/components/shared/Typography/CopyToClipboard';
import EmptyCell from 'core/components/shared/Typography/EmptyCell';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Utilities
import { convertToURL } from 'core/utilities/helper/helperPack';

// Custom Types
import type { TagListItemProps } from 'features/content/tags/types';
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

interface TagsDataGridProps extends Omit<DataGridProps, 'rows' | 'columns'> {
  tags?: TagListItemProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 150,
  },
  {
    field: 'col2',
    headerTitle: 'توضیح',
    width: 250,
    renderCell: (params) => (
      <Typography
        component='p'
        variant='body2'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '250px',
        }}
      >
        {params.row.col2}
      </Typography>
    ),
  },
  {
    field: 'col3',
    headerTitle: 'آدرس',
    width: 200,
    renderCell: (params) => {
      const url = convertToURL(`/tag/${params.row.col3}`);

      return url ? (
        <CopyToClipboard
          value={url}
          label={params.row.col3}
          wrapperProps={{
            sx: { dir: 'ltr', width: '100%', justifyContent: 'flex-start' },
          }}
        />
      ) : (
        <EmptyCell />
      );
    },
  },
  {
    field: 'col4',
    headerTitle: 'مطالب',
    align: 'center',
    width: 65,
    renderCell: (params) => <BodyTwo>{params.row.col4?.toPersian()}</BodyTwo>,
  },
];

const TagsDataGrid: React.FC<TagsDataGridProps> = (props) => {
  // Props
  const { tags, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    tags?.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.description ? data.description : '-',
        col3: data.slug,
        col4: data.count,
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [tags]);

  useEffect(() => {
    generateRows();
  }, [tags, generateRows]);

  return <DataGrid columns={columns} rows={rows} {...otherProps} />;
};

export default TagsDataGrid;
