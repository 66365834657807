import { useEffect, type FC, useCallback } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';

// Custom Common Components
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import SkinSelect from 'core/components/shared/Select/Skin';
import SlugTextField from 'core/components/shared/TextField/Slug';
import FileUploader from 'core/components/shared/Input/NewFileUploader';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import TextField from 'core/components/base/inputs/TextField';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Utilities
import { CategorySchema } from 'features/content/categories/validations';
import { getFileSource } from 'core/utilities/helper/helperPack';
import { addFiles } from 'features/file/files/utilities/files';
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';
import {
  useAddCategoryMutation,
  useEditCategoryMutation,
} from 'features/content/categories/hooks';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { CategoryProps } from 'features/content/categories/types';

export interface CategoryCardProps extends RoundPaperProps {
  category?: CategoryProps;
  onClose: () => void;
}

const CategoryCard: FC<CategoryCardProps> = (props) => {
  // Props
  const { onClose, onSubmit, category, sx, ...otherProps } = props;

  // Hooks
  const [refId] = useRefId();

  const resolver = useYupValidationResolver(CategorySchema);
  const { control, setValue, handleSubmit, reset } = useForm<CategoryProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...category,
    },
  });

  const { mutate: handleAddCategory } = useAddCategoryMutation({
    autoAlert: { mode: 'create', name: 'دسته‌بندی' },
    onSuccess: () => onClose(),
  });
  const { mutate: handleEditCategory } = useEditCategoryMutation({
    autoAlert: { mode: 'edit', name: 'دسته‌بندی' },
    onSuccess: () => {
      onClose();
    },
  });

  const loading = useSelectLoading();

  const initializeValues = useCallback(
    (category: CategoryProps) => {
      reset({ id: category.id, data: { ...category.data } });
    },
    [reset]
  );

  useEffect(() => {
    if (category) initializeValues(category);
  }, [category, initializeValues]);

  const handleFormSubmit: SubmitHandler<CategoryProps> = async (data) => {
    const updatedCategory = { ...data };
    setAppLoading(true);
    if (!updatedCategory.data.relatedMediaId)
      updatedCategory.data.relatedMediaId = refId;

    if (updatedCategory.data.image instanceof File) {
      const { status, files } = await addFiles({
        files: [updatedCategory.data.image],
        location: 'category',
        relatedModelId:
          category && category.data.relatedMediaId
            ? category.data.relatedMediaId
            : refId,
      });
      setAppAlert('');
      if (isSucceed(status)) {
        updatedCategory.data.image = files[0];
        setValue('data.image', files[0]);
      } else {
        setAppAlert('خطا در آپلود فایل، لطفا مجددا تلاش کنید.');
        setAppLoading(false);
        return;
      }
    }

    if (category)
      handleEditCategory({ id: category.id, data: updatedCategory.data });
    else handleAddCategory(updatedCategory.data);
  };

  return (
    <RoundPaper
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}
      {...otherProps}
    >
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <InputWrapper required label='عنوان' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.title'
            defaultValue=''
            render={({ field, fieldState }) => (
              <TextField
                error={
                  fieldState.isTouched && fieldState.error?.message
                    ? true
                    : false
                }
                helperText={fieldState.isTouched && fieldState.error?.message}
                disabled={loading}
                {...field}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper required label='اسکین' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.skinId'
            defaultValue=''
            render={({ field, fieldState }) => (
              <SkinSelect
                defaultValue={field.value}
                onChange={(skinId) => setValue('data.skinId', skinId)}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='اسلاگ' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.slug'
            defaultValue=''
            render={({ field, fieldState }) => (
              <SlugTextField
                dir='ltr'
                error={fieldState.error?.message ? true : false}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </InputWrapper>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}
      >
        <InputWrapper
          label='تصویر'
          sx={{ flexGrow: 1, maxWidth: '16rem', height: '9.5rem' }}
        >
          <Controller
            control={control}
            name='data.image'
            render={({ field }) => (
              <FileUploader
                location='category'
                accept={['image']}
                onUploadChange={(file) => field.onChange(file)}
                onUploadRemove={() => field.onChange(null)}
                disabled={loading}
                sx={{
                  maxHeight: '8rem',
                }}
                previewProps={{
                  previewSrc: field.value ? getFileSource(field.value) : '',
                }}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='توضیحات' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.description'
            render={({ field }) => <TextField multiline rows={5} {...field} />}
          />
        </InputWrapper>
      </Box>
      <RowStack direction='row-reverse'>
        <LoadingButton
          color='info'
          variant='contained'
          type='submit'
          sx={{ width: '10rem' }}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </LoadingButton>
        <Button color='info' variant='outlined' onClick={onClose}>
          بستن
        </Button>
      </RowStack>
    </RoundPaper>
  );
};

export default CategoryCard;
